
import { defineComponent } from 'vue'
import Course from '@/types/course'
import AdminNav from '@/components/AdminNav.vue'
import getCourseImageUrlMixin from '@/utils/getCourseImageUrlMixin'

export default defineComponent({
  mixins: [getCourseImageUrlMixin],
  components: { AdminNav },
  data () {
    return {
      courses: [] as Course[]
    }
  },
  async mounted () {
    const response = await this.$api.listCourses()
    this.courses = response.courses || []
  }
})
