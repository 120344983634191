<template>
  <div class="container-fluid pt-3">
    <div class="row">
      <admin-nav />
      <div class="col-lg-10 col-sm-12">
        <div class="container">
          <h4 class="mb-3">Manage courses</h4>
          <div class="mb-3">
            <router-link class="btn btn-success" :to="{ name: 'admin_course', params: { id: 'new' } }">Create new course</router-link>
          </div>
          <div class="row">
            <div class="col-lg-4 col-sm-12" v-for="(course, index) in courses" :key="index">
              <div class="card">
                <img class="card-img-top" :src="getCourseImageUrl(course.id)" alt="Card image cap">
                <div class="card-body">
                  <h5 class="card-title">{{ course.name }}</h5>
                  <p class="card-text">{{ course.description }}</p>
                  <router-link class="btn btn-primary" :to="{ name: 'admin_course', params: { id: course.id } }">Edit/view details</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Course from '@/types/course'
import AdminNav from '@/components/AdminNav.vue'
import getCourseImageUrlMixin from '@/utils/getCourseImageUrlMixin'

export default defineComponent({
  mixins: [getCourseImageUrlMixin],
  components: { AdminNav },
  data () {
    return {
      courses: [] as Course[]
    }
  },
  async mounted () {
    const response = await this.$api.listCourses()
    this.courses = response.courses || []
  }
})
</script>
